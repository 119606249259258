@import "compass";
@import "../../theme_variables";

.search-widget {
	@media (max-width: $screen-sm) {
		text-align: center;
	}
  input.search_query {
    display: inline-block;
    margin-top: 5px;
    float: left;
    width: auto;
    outline: none;
    padding: 10px;
    height: auto;
    font-size: 14px;
  }
  form {
  	display: inline-block;
  }
  button {
    background: none !important;
    color: inherit;
    border: none;
    padding: 0! important;
    font: inherit;
    cursor: pointer;
    outline: none !important;
    position: relative;
    float:left;

    .icon {
      font-size: 25px;
      position: absolute;
      top: 12px;
      &:hover {
        color: $beige;
      }

    }
  }
}

/*     
#search_block_top {
	#searchbox {
		float: left;
		width: 100%;
	}
	.btn.button-search {
		background: $dark-background;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		border: none;
		color: $light-text-color;
		width: 50px;
		text-align: center;
		padding: 10px 0 11px 0;
		span {
			display: none;
		}
		&:before {
			content: "\f002";
			display: block;
			font-family: $font-icon;
			font-size: 17px;
			width: 100%;
			text-align: center;
		}
		&:hover {
			color: #6f6f6f
		}
	}
	#search_query_top {
		display: inline;
		padding: 13px 60px 13px 13px;
		height: 45px;
		background: $base-box-bg;
		margin-right: 1px;
	}
}

.ac_results {
	background: $light-background;
	border: 1px solid $base-border-color;
	width: 271px;
	margin-top: -1px;
	li {
		padding: 0 10px;
		font-weight: normal;
		color: #686666;
		font-size: 13px;
		line-height: 22px;
		&.ac_odd {
			background: $light-background;
		}
		&:hover, &.ac_over {
			background: $base-box-bg;
		}
	}
}

form#searchbox {
	position: relative;
	label {
		color: $base-text-color;
	}
	input#search_query_block {
		margin-right:10px;
		max-width:222px;
		margin-bottom:10px;
		display:inline-block;
		float:left;
	}
	.button.button-small {
		float:left;
		i {
			margin-right:0;
		}
	}
} */